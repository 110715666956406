import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareFilterParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/admin/stock-request`;

export default {
  index(payload) {
    const params = prepareFilterParams(payload, payload.queries);
  
    return Client.post(resource, {
      ...params
    }, {
      headers: {
        action: "list",
      },
    });
  },
};
