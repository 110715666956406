import ProfileRepository from "./Api/ProfileRepository";
import DashboardRepository from "./Api/DashboardRepository";
import UserRepository from "./Api/UserRepository";
import RoleRepository from "./Api/RoleRepository";
import PermissionRepository from "./Api/PermissionRepository";
import EmployeeRepository from "./Api/EmployeeRepository";
import CompanyRepository from "./Api/CompanyRepository";
import DepartmentRepository from "./Api/DepartmentRepository";
import PositionRepository from "./Api/PositionRepository";
import UnitRepository from "./Api/UnitRepository";
import FileRepository from "./Api/FileRepository";
import ItemRepository from "./Api/ItemRepository";
import ItemCategoryRepository from "./Api/ItemCategoryRepository";
import ItemSectionRepository from "./Api/ItemSectionRepository";
import StockLocationRepository from "./Api/StockLocationRepository";
import ItemTypeRepository from "./Api/ItemTypeRepository";
import StockBinRepository from "./Api/StockBinRepository";
import RequestPurposeRepository from "./Api/RequestPurposeRepository";
import RequestSectionRepository from "./Api/RequestSectionRepository";
import SupplierRepository from "./Api/SupplierRepository";
import StockAdminRepository from "./Api/StockAdminRepository";
import StockRepository from "./Api/StockRepository";
import StockRequestRepository from "./Api/StockRequestRepository";
import InventoryReportRepository from "./Api/InventoryReportRepository";
import UserForReportRepository from "./Api/UserForReportRepository";
import StockReportRepository from "./Api/StockReportRepository";

const repositories = {
  'profile': ProfileRepository,
  'dashboard': DashboardRepository,
  'user': UserRepository,
  'role': RoleRepository,
  'permission': PermissionRepository,
  'employee': EmployeeRepository,
  'company': CompanyRepository,
  'department': DepartmentRepository,
  'position': PositionRepository,
  'unit': UnitRepository,
  'file': FileRepository,
  'item': ItemRepository,
  'itemCategory': ItemCategoryRepository,
  'itemSection': ItemSectionRepository,
  'itemType': ItemTypeRepository,
  'stockLocation': StockLocationRepository,
  'stockBin': StockBinRepository,
  'requestPurpose': RequestPurposeRepository,
  'requestSection': RequestSectionRepository,
  'supplier': SupplierRepository,
  'stockAdmin': StockAdminRepository,
  'stock': StockRepository,
  'stockRequest': StockRequestRepository,
  'inventoryReport': InventoryReportRepository,
  'userForReport': UserForReportRepository,
  'stockReport': StockReportRepository,
};

export default {
  get: name => repositories[name]
};
